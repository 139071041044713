
import { Component, Vue, Watch } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import Table from '@/components/Table/Table.vue'
import FButton from '@/components/Button/FButton.vue'
import FForm from '@/components/Form/FForm.vue'
import FInput from '@/components/Form/FInput.vue'
import FSelect from '@/components/Form/FSelect.vue'
import FCheckbox from '@/components/Form/FCheckbox.vue'
import CImage from '@/components/Image/CImage.vue'
import Modal from '@/components/Modal/Modal.vue'
import DirectDepositHelp from './DirectDepositHelp.vue'
import { PaymentSettingData } from '@/models/PaymentSettingData'
import { PaymentSettingLocationData } from '@/models/PaymentSettingLocationData'
import { PaymentSettingsDetails } from '@/models/PaymentSettingsDetails'
import { Country } from '@/models/Country'
import { TableColumn } from '@/models/TableColumn';
import { SelectOption } from '@/models/SelectOption'
import { ApiService } from '@/services/ApiService'
import { ProfileService } from '@/services/ProfileService'
import { AccountHolder } from '@/models/AccountHolder'
import { BusyService } from '@/services/BusyService'
import HelpIcon from '@/components/HelpIcon/HelpIcon.vue'
import { ToastService } from '@/services/ToastService'
import ConfirmService from '@/services/ConfirmService';
import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'
import { CustomError } from '@/models/CustomError'

@Component({
  components: {
    CardWithTitle,
    Table,
    FButton,
    FForm,
    FInput,
    FSelect,
    FCheckbox,
    Modal,
    DirectDepositHelp,
    CImage,
    HelpIcon
  },
  metaInfo: () => ({
    title: DynamicTranslationService.paymentSettingsTitle
  }),
  beforeRouteEnter: (to, from, next) => {
    if (ProfileService.enableDirectDeposit) {
      next();
    } else {
      next('/payment')
    }
  }
})
export default class DirectDeposit extends Vue {
  formData: PaymentSettingData = {
    paymentSettingId: '',
    organizationId: '',
    paymentTypeCode: '',
    accountNumber: '',
    routingNumber: '',
    swiftBicNumber: '',
    ibanNumber: '',
    bankName: '',
    bankStreetAddress: '',
    bankCity: '',
    bankDistrict: '',
    bankPostalCode: '',
    bankCountryCode: '',
    branchName: '',
    accountTypeCode: '',
    accountHolderNameId: '',
    accountHolderName: '',
  };

  locationData: PaymentSettingLocationData = {
    paymentSettingId: '',
    payoutLocations: [
      'NA',
      'UKIE',
      'EU',
      'AP',
      'AF',
      'KR',
    ]
  };

  paymentSettings: PaymentSettingsDetails | null = null
  countryLocations: Country[] = [];
  financialInstitutionDistricts: Record<string, SelectOption[]> = {};

  partyCountry = '';
  countryLocation = '';
  transitNumber = '';
  institutionNumber = '';
  confirmAccountNumber = '';

  isDefaultPayment = true;
  isFalse = false

  get tableColumns(): TableColumn[] {
    return [
      { key: 'type', label: i18n.t('R.LIT_Type').toString() },
      { key: 'details', label: i18n.t('R.LIT_Details').toString() },
    ];
  }

  get countryLocationOptions(): SelectOption[] {
    return this.countryLocations
      .sort((a, b) => a.countryName.localeCompare(b.countryName))
      .map(c => ({ value: c.countryCode, label: c.countryName }));
  }

  get accountTypeOptions(): SelectOption[] {
    const options = [
      {
        value: '',
        label: i18n.t('R.LIT_Select').toString()
      },
      {
        value: 'CHECKING',
        label: i18n.t('R.LIT_Checking').toString()
      },
      {
        value: 'SAVING',
        label: i18n.t('R.LIT_Savings').toString()
      }
    ];

    if (this.countryCode === 'AU' || this.countryCode === 'NZ') {
      return options.filter(option => option.value !== 'CHECKING');
    }

    return options;
  }

  get financialInstitutionDistrictsOptions(): SelectOption[] {
    const defaultOption: SelectOption = {
      value: '',
      label: this.$t('R.LIT_Select').toString()
    };

    const options = Object.values(this.financialInstitutionDistricts).flat();

    return [defaultOption, ...options];
  }

  get payoutLocations() {
    return [
      {
        value: 'NA',
        label: 'North America',
        disabled: this.lockedCountries?.includes('NA') || false,
        forceDisable: false
      },
      {
        value: 'UKIE',
        label: 'UK & Ireland',
        disabled: this.lockedCountries?.includes('UKIE') || false,
        forceDisable: false
      },
      {
        value: 'AP',
        label: 'Asia Pacific',
        disabled: this.lockedCountries?.includes('AP') || false,
        forceDisable: false
      },
      {
        value: 'AF',
        label: 'Africa',
        disabled: this.lockedCountries?.includes('AF') || false,
        forceDisable: false
      },
      {
        value: 'EU',
        label: 'Mainland Europe',
        disabled: this.lockedCountries?.includes('EU') || false,
        forceDisable: false
      },
      {
        value: 'KR',
        label: 'Korea',
        disabled: this.lockedCountries?.includes('KR') || false,
        forceDisable: this.partyCountry !== 'KR' || false
      }
    ]
  }

  get accountHolderNameOptions(): SelectOption[] {
    const options = this.availableAccountHolderNames
      .map(n => ({ value: n.partyNameId, label: this.decodeHtmlEntities(n.partyName) }));

    options.unshift({
      value: '',
      label: i18n.t('R.LIT_Select').toString()
    });
    options.push({
      value: 'NEW',
      label: this.$t('R.LIT_AddNew').toString()
    })

    return options;
  }

  get countryRegion(): string {
    return this.countryLocations.find(c => c.countryCode === this.countryLocation)?.regionName || '';
  }

  get countryCode(): string {
    return this.countryLocations.find(c => c.countryCode === this.countryLocation)?.countryCode || '';
  }

  get regionNorthAmerica(): boolean {
    return this.countryRegion === 'CCLI North America Region';
  }

  get regionEurope(): boolean {
    return this.countryRegion === 'CCLI Europe Region';
  }

  get regionAsiaPacific(): boolean {
    return this.countryRegion === 'CCLI Asia Pacific Region';
  }

  get regionLatin() {
    return this.countryRegion === 'CCLI Latin America Region';
  }

  get regionAfrica(): boolean {
    return this.countryRegion === 'CCLI Africa Region';
  }

  get sortCodeExeptions() {
    return this.regionAfrica || this.countryLocation === "UK" || this.countryLocation === 'IE' || this.countryLocation === 'SE'
  }

  get showRoutingField() {
    return this.sortCodeExeptions || this.countryLocation === 'DE' || !this.regionEurope;
  }

  get showExampleCheck() {
    return this.countryLocation === 'CA' || this.countryLocation === 'US';
  }

  get routingLabel() {
    if (this.countryLocation === 'AU') {
      return this.$t("R.LIT_BSB");
    } else if (this.countryLocation === 'NZ') {
      return this.$t("R.LIT_BankBranchCode");
    }
    else if (this.countryLocation === 'BR') {
      return this.$t("R.LIT_BranchId");
    }
    else if (this.countryLocation === 'DE') {
      return this.$t("R.LIT_RoutingCode");
    }
    else if (this.countryLocation === 'US') {
      return this.$t("R.LIT_RoutingNumber");
    }
    else if (this.sortCodeExeptions) {
      return this.$t("R.LIT_SortCode");
    }
    else {
      return this.$t("R.LIT_SortRoutingCode");
    }
  }

  get bankDistrictLabel() {
    if (this.countryLocation === 'AU') {
      return this.$t('R.LIT_StateTerritory');
    }
    else if (this.countryLocation === 'US' || this.countryLocation === 'BR' || this.countryLocation === 'MX') {
      return this.$t('R.LIT_State');
    }
    else if (this.countryLocation === 'UK' || this.countryLocation === 'IE') {
      return this.$t('R.LIT_CountyDistrict');
    }
    else if (this.countryLocation === 'CA') {
      return this.$t('R.LIT_Province');
    }
    else if (this.countryLocation === 'JP') {
      return this.$t('R.LIT_TownPrefecture');
    }
    else if (this.countryLocation === 'KR') {
      return this.$t('R.LIT_CountyPrecinctProvince')
    }
    else {
      return this.$t('R.LIT_District')
    }
  }

  get bankPostalCodeLabel() {
    if (this.countryCode === 'AU') {
      return this.$t('R.LIT_Postcode')
    } else if (this.countryCode === 'US' || this.countryCode === 'CA') {
      return this.$t('R.LIT_ZipCode')
    }
    return this.$t('R.LIT_PostalCode')
  }

  get hasTransitInput() {
    return this.countryLocation === 'CA';
  }

  get hasBankBranchNameInput() {
    return this.regionAfrica || this.regionAsiaPacific;
  }

  get hasAccountTypeInput() {
    return this.countryLocation === 'CA' || this.countryLocation === 'US' || this.countryLocation === 'BR' || 'ZA';
  }

  get hasInstitutionNumberInput() {
    return this.countryLocation === 'CA';
  }

  get hasIbanInput() {
    const ibanCountries = [
      'AN', 'DZ', 'BH', 'BR', 'VG', 'CR', 'DO', 'EG', 'FO', 'GE', 'GL', 'IR', 'IQ', 'IL', 'JO', 'KZ', 'KW', 'LB', 'MR', 'MU', 'ME', 'PK', 'PS', 'QA', 'SA', 'TN', 'AE'
    ];
    return this.regionEurope || ibanCountries.includes(this.countryLocation);
  }

  get requireSwiftBic() {
    return this.countryLocation !== 'US' && this.countryLocation !== 'CA' && this.countryLocation !== 'AU' && this.countryLocation !== 'NZ';
  }

  get lockedCountries() {
    return this.paymentSettings?.lockedCountries;
  }

  get showPayoutLocations() {
    if (this.lockedCountries) {
      return this.lockedCountries.length < this.payoutLocations.length;
    } else {
      return true;
    }
  }

  get confirmAccountNumberMatches() {
    return this.confirmAccountNumber === this.formData.accountNumber && !!this.confirmAccountNumber;
  }

  get CannotSetPayoutLocationMessage() {
    return this.$t('R.MES_CannotSetRegionPayoutLocation');
  }

  availableAccountHolderNames: AccountHolder[] = [];

  async mounted() {
    if (!ProfileService.profile) {
      return;
    }
    BusyService.showBusy();
    this.formData.organizationId = ProfileService.profile.org.id;
    this.countryLocation = ProfileService.profile.org.countryCode.toUpperCase();
    this.partyCountry = ProfileService.profile.org.countryCode.toUpperCase();
    if(this.countryLocation === 'GB'){
      this.countryLocation = 'UK';
    }
    if (!this.countryLocation) {
      this.countryLocation = 'US';
    }
    await this.confirmRoutingInstitution();
    this.availableAccountHolderNames = await ApiService.post('/api/GetDirectDepositAccountHolderNames', { organizationId: ProfileService.profile.org.id });
    this.paymentSettings = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id });
    this.countryLocations = await ApiService.post('/api/GetCountryDetails');
    if (this.partyCountry !== 'KR') {
      this.locationData.payoutLocations = this.locationData.payoutLocations.filter(l => l !== 'KR');
    }
    this.locationData.payoutLocations = this.locationData.payoutLocations.filter(l => !this.paymentSettings?.lockedCountries.includes(l));
    try {
      this.GetCountryDistricts();
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
    BusyService.hideBusy();
  }

  financialInstitution = '';
  financialInstitutionErrMsg = '';

  async confirmRoutingInstitution() {
    this.formData.bankCountryCode = this.countryLocation;
    if (this.countryLocation !== 'US') {
      this.financialInstitution = '';
      this.financialInstitutionErrMsg = '';
      if (this.transitNumber && this.institutionNumber) {
        this.formData.routingNumber = '0' + this.institutionNumber + this.transitNumber;
      }
      return;
    }
    if (!this.formData.routingNumber) {
      this.financialInstitution = '';
      this.financialInstitutionErrMsg = '';
      return;
    }
    BusyService.showBusy();

    try {
      this.financialInstitution = await ApiService.post('/api/GetFinancialInstitution', { routingNumber: this.formData.routingNumber });
      this.financialInstitutionErrMsg = '';
    } catch (err: any) {
      this.financialInstitution = '';
      if (err?.data?.publicStatusMessage) {
        this.financialInstitutionErrMsg = err.data.publicStatusMessage;
      } else {
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }
    }

    BusyService.hideBusy();
  }

  bankBranchError = '';
  async validateBankBranchCode() {
    if (!this.formData.routingNumber) {
      this.bankBranchError = '';
    }
    if (!this.confirmBankBranchCodeValidation()) {
      this.financialInstitutionErrMsg = this.$t('R.MES_BankBranchCodeError').toString();
    }
    else {
      this.bankBranchError = '';
    }
  }

  confirmBankBranchCodeValidation() {
    return /^[0-9-]+$/.test(this.formData.routingNumber);
  }

  checkExistingPaymentLocations(): boolean {
    if (this.paymentSettings) {
      for (const payment of this.paymentSettings.paymentSettings) {
        const locationIsBeingUsed = payment.payoutLocations.some(location => this.locationData.payoutLocations.includes(location));
        if (locationIsBeingUsed) {
          return true;
        }
      }
    }
    return false;
  }

  async onSubmit() {
    if ((this.$refs.form as FForm).hasFormError || !this.locationData.payoutLocations.length) {
      return;
    }

    const checkExistingPaymentLocations = this.checkExistingPaymentLocations();
    if(checkExistingPaymentLocations){
        const success = await ConfirmService.showConfirm({
          title: this.$t('G.LIT_Submit').toString(),
          message: this.$t('R.MES_PaymentAddConfirmationMsg').toString(),
          okLabel: this.$t('G.LIT_Yes').toString(),
          cancelLabel: this.$t('G.LIT_Cancel').toString()
        });

        if (!success){
          return
        }
    }
    BusyService.showBusy();
    const response = await ApiService.post('/api/SetPaymentSetting', { data: JSON.stringify(this.formData) });
    if (typeof response === 'string') {
      this.locationData.paymentSettingId = response;
    } else {
      ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
    }
    await ApiService.post('/api/SetPaymentSettingLocations', { data: JSON.stringify(this.locationData) })
    if (this.paymentSettings && this.paymentSettings.paymentSettings.length > 0) {
      this.paymentSettings.paymentSettings.map(p => p).forEach(async (payment) => {
        if (payment.paymentSettingId !== response) {
          const filterLocationData = payment.payoutLocations.filter(l => !this.locationData.payoutLocations.includes(l));
          const payoutLocationData = {
            paymentSettingId: payment.paymentSettingId,
            payoutLocations: filterLocationData
          }
          await ApiService.post('/api/SetPaymentSettingLocations', { data: JSON.stringify(payoutLocationData) });
        }
      });
    }

    // to ensure the payoutlocations are updated in the payment settings page
    await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile?.org.id });

    BusyService.hideBusy();
    await this.$router.push('/payment');
    ToastService.successToast(this.$t('R.LIT_DirectDepositSuccessfullySubmitted').toString())
  }

  confirmAccountNumberErr = '';

  onConfirmAccountNumberInput() {
    if (!this.confirmAccountNumberMatches) {
      this.confirmAccountNumberErr = this.$t('R.MES_AccountNumbersDontMatch').toString();
    } else {
      this.confirmAccountNumberErr = '';
    }
  }

  accountNumberErr = ''
  validateAccountNumber() {
    if (!this.formData.accountNumber) {
      this.accountNumberErr = '';
    } else if (this.formData.accountNumber.match(/^[0-9]+$/) === null) {
      this.accountNumberErr = this.$t('R.MES_NumericDigitsOnly').toString();
    } else if (!(this.formData.accountNumber.length >= 2 && this.formData.accountNumber.length <= 17)) {
      this.accountNumberErr = this.$t('R.MES_AccountLengthErrorMsg').toString();
    } else {
      this.accountNumberErr = '';
    }
    this.onConfirmAccountNumberInput();
  }

  confirmSwiftBicCodeErr = ''
  onConfirmSwiftBicCode() {
    if (!this.formData.swiftBicNumber) {
      this.confirmSwiftBicCodeErr = '';
    } else if (!(this.formData.swiftBicNumber.length === 8 || this.formData.swiftBicNumber.length === 11)) {
      this.confirmSwiftBicCodeErr = this.$t('R.MES_SwiftBicCodeMessage').toString()
    } else {
      this.confirmSwiftBicCodeErr = '';
    }
  }

  confirmIbanErr = ''
  onConfirmIban() {
    const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
    if (!this.formData.ibanNumber) {
      this.confirmIbanErr = '';
    }
    else if (!ibanRegex.test(this.formData.ibanNumber)) {
      this.confirmIbanErr = this.$t('R.MES_InvalidIban').toString();
    }
    else {
      this.confirmIbanErr = '';
    }
  }

  onAccountHolderInput() {
    if (this.formData.accountHolderNameId === 'new' || !this.formData.accountHolderNameId) {
      this.formData.accountHolderName = '';
    } else {
      this.formData.accountHolderName = this.availableAccountHolderNames.find(n => n.partyNameId === this.formData.accountHolderNameId)?.partyName || '';
    }
  }

  isCountryLocked(locationValue: string) {
    return this.lockedCountries ? this.lockedCountries.includes(locationValue) : false;
  }

  updatePayoutLocations(locationValue: string, isChecked: boolean) {
    if (isChecked) {
      if (locationValue === 'ALL') {
        this.locationData.payoutLocations = this.payoutLocations.filter(l => !l.disabled && !l.forceDisable).map(l => l.value);
        return;
      }
      this.locationData.payoutLocations.push(locationValue);
    } else {
      const index = this.locationData.payoutLocations.indexOf(locationValue);
      if (index !== -1) {
        this.locationData.payoutLocations.splice(index, 1);
      }
    }
  }

  async GetCountryDistricts() {
    try {
      const response = await ApiService.post<Record<string, SelectOption[]>>('/api/GetCountryDistricts', { countryCode: this.countryCode });
      this.financialInstitutionDistricts = response;
    } catch (error) {
      console.error('Error fetching country districts:', error);
    }
  }

  decodeHtmlEntities(text: string): string {
    return new DOMParser().parseFromString(text, 'text/html').body.textContent || '';
  }

  resetPaymentForm() {
    this.formData.accountNumber = '';
    this.formData.routingNumber = '';
    this.formData.swiftBicNumber = '';
    this.formData.ibanNumber = '';
    this.formData.bankName = '';
    this.formData.bankStreetAddress = '';
    this.formData.bankCity = '';
    this.formData.bankDistrict = '';
    this.formData.bankPostalCode = '';
    this.formData.branchName = '';
    this.formData.accountTypeCode = '';
    this.isDefaultPayment = true;
    this.confirmAccountNumber = '';
    this.transitNumber = '';
    this.institutionNumber = '';
    this.financialInstitution = '';
  }

  @Watch('countryLocation')
  onCountryLocationChange() {
    this.GetCountryDistricts();
    this.resetPaymentForm();
  }
}
